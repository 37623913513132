// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contribute-js": () => import("./../../../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-editor-how-to-js": () => import("./../../../src/pages/editor-how-to.js" /* webpackChunkName: "component---src-pages-editor-how-to-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ip-js": () => import("./../../../src/pages/ip.js" /* webpackChunkName: "component---src-pages-ip-js" */),
  "component---src-pages-player-how-to-js": () => import("./../../../src/pages/player-how-to.js" /* webpackChunkName: "component---src-pages-player-how-to-js" */),
  "component---src-pages-player-js": () => import("./../../../src/pages/player.js" /* webpackChunkName: "component---src-pages-player-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-useful-resources-js": () => import("./../../../src/pages/useful-resources.js" /* webpackChunkName: "component---src-pages-useful-resources-js" */),
  "component---src-templates-sections-player-template-js": () => import("./../../../src/templates/sections-player-template.js" /* webpackChunkName: "component---src-templates-sections-player-template-js" */)
}

